<template>
  <div>
    <Loading v-show="show" />
    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text breadcrumb-text1">
              <h1 class="text-center">{{ VideosTitle }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Course Area Start-->
    <div class="course-area section-padding course-page">
      <div class="container">
        <div class="row">
          <div
            v-for="videos in Videos"
            :key="videos.postID"
            class="col-lg-4 col-md-6 col-12"
          >
            <div class="single-item">
              <div class="single-item-image overlay-effect">
                <a
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  ><img
                    loading="lazy"
                    :src="
                      'https://api2.yuniv.net:444/images/post/' +
                      videos.postImage
                    "
                    alt=""
                  />
                  <i class="fa fa-play-circle"></i>
                </a>
              </div>
              <div class="single-item-text">
                <h4>{{ videos.postTitle }}</h4>
              </div>
              <div class="button-bottom">
                <a
                  style="cursor: pointer"
                  @click="showVideo(videos.postID)"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  class="button-default"
                  >{{ watch }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Course Area-->
    <!-- Modal -->
    <div
      class="modal fade" style="background: rgba(0,0,0,0.5);"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe
              width="100%"
              style="height: 500px"
              class="mfp-iframe"
              id="postURL"
              frameborder="0"
              allowfullscreen=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";
import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      Videos: [],
      watch: "",
      VideosTitle: "",
      //translate
      translate: ArJson,
      ArTranslat: ArJson,
      EnTranslat: EnJson,
    };
  },
  methods: {
    getApis() {
      var self = this;
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        self.translate = self.EnTranslat;
      }

      self.watch = self.translate[0]["Home"]["watch"];
      if (self.$route.params.type == "Univ.Research.Home.Videos") {
        self.VideosTitle = self.translate[0]["Home"]["LibraryVideos"];
      } else {
        self.VideosTitle = self.translate[0]["Home"]["YunivVideos"];
      }

      var bodyFormData3 = new FormData();
      bodyFormData3.append("check", "getPostDetails");
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("PostId", "");
      bodyFormData3.append("FbrnId", "");
      bodyFormData3.append("Type", this.$route.params.type);
      bodyFormData3.append("ParentId", "NTROOT0");
      bodyFormData3.append("PostName", "");
      bodyFormData3.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData3,
      })
        .then(function (response) {
          self.Videos = response.data;
          setTimeout(() => {
            self.show = "false";
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function () {
          //   console.log("error", response);
        });
    },
    showVideo(id) {
      var bodyFormData3 = new FormData();
      bodyFormData3.append("check", "getPostById");
      bodyFormData3.append("PostId", id);
      bodyFormData3.append("Lang", localStorage.getItem("lang"));
      bodyFormData3.append("FbrnId", "");
      bodyFormData3.append("Type", this.$route.params.type);
      bodyFormData3.append("ParentId", "NTROOT0");
      bodyFormData3.append("PostName", "");
      bodyFormData3.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData3,
      }).then(function (response) {
        document
          .getElementById("postURL")
          .setAttribute("src", response.data[0]["url"]);
      });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }
        })
        .catch(function () {
          //   console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
  },
};
</script>
<style scoped>
.single-item {
  margin-bottom: 80px;
}
.modal-body {
  padding: 0;
}
.modal-header {
  border-bottom: 0;
}
.modal-header .close {
  color: #fff;
  opacity: 1;
  margin: -1rem -1rem -1rem auto;
}
.modal-content {
  background-color: transparent;
  border: none;
}
</style>
